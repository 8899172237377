
const pageDisplay = 6;
const pageDisplayEmail = 1;
const fetchLimit = 20;
const modeDeploy = 'environment';

const homeURL =   "https://www.mediasfu.com/api" 

module.exports = {
    pageDisplay,
    pageDisplayEmail,
    fetchLimit,
    modeDeploy,
    homeURL   
}