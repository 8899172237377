
module.exports = {

    getForumReplies: async (specificPostReplyInput,force,adminKey) => {
        const requestBody = {
            query: `
              query PostRepliesSpecific($input: SpecificPostReplyInput!, $force: Boolean, $adminKey: String) {
                postReplies_specific(input: $input, force: $force, adminKey: $adminKey) {
                    _id
                    title
                    content
                    user{
                        _id
                        username
                        email
                        createdAt
                        role
                        numberPosts
                        numberPostReplies
                        numberLikedPosts
                    }
                    subuser{
                        _id
                        username
                        email
                        createdAt
                        role
                        numberPosts
                        numberPostReplies
                        numberLikedPosts
                    }
                    timeStamp
                    upvotes
                    likes
                    category
                    views
                    attachments
                    replyNumber
                    createdAt
                    updatedAt
                    post{
                        _id
                        user{
                            _id
                            username
                            email
                            createdAt
                            role
                        }
                        subuser{
                            _id
                            username
                            email
                            createdAt
                            role
                        }
                    }
                }
              }
            `,
            variables: {
          
                input: specificPostReplyInput,
                force: force,
                adminKey: adminKey
              },
              
            
          };

        return requestBody;
    },
      

    createForumReplyInput: async (postreplyInput, force, adminKey) => {


        const requestBody = {
            query: `
                mutation CreatePostReply($input: PostReplyInput!,$force: Boolean, $adminKey: String) {
                createPostReply(input: $input,force: $force, adminKey: $adminKey) {
                    _id
                }
                }
            `,
            variables: {
                input: postreplyInput,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },


    updateForumReplyInput: async (id, postreplyInput, force, adminKey) => {
        const requestBody = {
            query: `
            mutation UpdatePostReply($id: ID!, $input: PostReplyInput!,$force: Boolean, $adminKey: String) {
              updatePostReply(id: $id, input: $input,force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                id: id,
                input: postreplyInput,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },

    updateForumReplyInputArray: async (ids, inputArray, force, adminKey) => {
        const requestBody = {
            query: `
            mutation UpdatePostRepliesArray($ids: [ID!]!, $inputArray: [PostReplyInput!]!, $force: Boolean, $adminKey: String) {
              updatePostRepliesArray(ids: $ids, inputArray: $inputArray, force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                ids: ids,
                inputArray: inputArray,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },


    deleteForumReplyInput: async (id, force, adminKey) => {
        const requestBody = {
            query: `
            mutation DeletePostReply($id: ID!, $force: Boolean, $adminKey: String) {
              deletePostReply(id: $id, force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                id: id,
                force: force,
                adminKey: adminKey,
            },
        };
        return requestBody;
    },

    deleteForumReplyInputArray: async (ids, force, adminKey) => {
        const requestBody = {
            query: `
            mutation DeletePostRepliesArray($ids: [ID!]!, $force: Boolean, $adminKey: String) {
              deletePostRepliesArray(ids: $ids, force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                ids: ids,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },


}






