const {modeDeploy} = require('../../../codes');

module.exports = {


  loginUserAlt: async (userInput, force, adminKey) => {

    const requestBody = {
      query: `
        query LoginUser($userInput: UserInput!, $force: Boolean, $adminKey: String) {
          loginUser(userInput: $userInput, force: $force, adminKey: $adminKey) {
            userId
            userEmail
            userName
            token
            reset_token
            newIPorDevice
            isVerified
            role
            tokenExpiration
          }
        }
      `,
      variables: {
        userInput: userInput,
        force: force,
        adminKey: adminKey
      },
    };

    return requestBody;
  },


  loginUserQuery: async (userInput, baseUrl) => {

    const requestBody = {
      query: `

        query loginUser ($userInput:UserInput!){
          loginUser(userInput:$userInput)
            {userId
            userEmail
            userName
            token
            reset_token
            newIPorDevice
            isVerified
            role
            tokenExpiration}
          }

        `,
      variables: {
        userInput: userInput
      }
    }


    return requestBody
  },

  registerUserQuery: async (userInput, baseUrl) => {
    const requestBody = {
      query: ` 
        mutation CreateUser($input: UserInput!) { 
          createUser(input: $input) {
            email
            _id
            password
          }
        }
      `,
      variables: {
        input: userInput
      }
    };

    return requestBody;
  },

  getUser: async (userInput, baseUrl) => {

    const requestBody = {
      query: `

      query getUser($userInput:UserInput!){
        getUser(userInput:$userInput)
          {userId
          reset_token
          userName
          role
            status}
        }

      `,
      variables: {
        userInput: userInput
      }
    }

    return requestBody
  },

  checkUser: async (userInput, baseUrl) => {

    const requestBody = {
      query: `

      query checkUser($userInput:UserInput!){
        checkUser(userInput:$userInput)
          {userId
          reset_token
          userName
          role
          userEmail
            status}
        }

      `,
      variables: {
        userInput: userInput
      }
    }

    return requestBody
  },

  updateUser: async (id, userInput) => {

    const requestBody = {
      query: `
      mutation UpdateUser($id: ID!, $input: UserInput!) {
        updateUser(id: $id, input: $input) {
          status
        }
      }
    `,
      variables: {
        id: id,
        input: userInput,
      },
    };
    return requestBody;
  },

  deleteUser : async (email, force, adminKey) => {
    const requestBody = {
      query: `
        mutation DeleteUser($email: String!, $force: Boolean, $adminKey: String) {
          deleteUser(email: $email, force: $force, adminKey: $adminKey) {
            status
          }
        }
      `,
      variables: {
        email: email,
        force: force,
        adminKey: adminKey,
      },
    };
    return requestBody;
  },

  updateUserAdmin: async (id, userInputAdmin, force, adminKey) => {

    const requestBody = {
      query: `
      mutation UpdateUserAdmin($id: ID!, $input: UserInputAdmin!, $force: Boolean, $adminKey: String) {
        updateUserAdmin(id: $id, input: $input, force: $force, adminKey: $adminKey) {
          status
        }
      }
    `,
      variables: {
        id: id,
        input: userInputAdmin,
        force: force,
        adminKey: adminKey,
      },
    };
    return requestBody;
  },

  checkTokenQuery: async (token, baseUrl) => {

    const requestBody = {
      query: `
          query checkToken($token: String!) {
              checkToken(checkTokenInput: { token: $token }) {
                  status
              }
          }
      `,
      variables: {
        token: token,
      },
    };

    return requestBody;
  },

  createActivityQuery: async (activityInput, baseUrl) => {
    const requestBody = {
      query: `
      mutation CreateActivity($input: ActivityInput!) {
        createActivity(input: $input) {
          loginIP
          geolocation
        }
      }
    `,
      variables: {
        input: activityInput,
      },
    };

    return requestBody;
  },

  getUserSummary: async (findUserInput,force, adminKey) => {

    const requestBody = {
      query: `

    query getUserSummary($input: findUserInput!,$force: Boolean, $adminKey: String)
      {
        getUserSummary(input: $input,force: $force, adminKey: $adminKey)
          { 

            _id
            email
            username
            phone
            isVerified
            lastVerified
            displayPicture
            userRating
            comment
            accountLoginAttempts
            temporarilyBlocked
            role
            status
            userType
            userRecordings
            userSubscriptionValidity
            posts{
              _id
              postNumber
            }
            postReplies{
              _id
              replyNumber
            }
            likedPosts{
              _id
              postNumber
            }
            rateLimits{
              createUser
              updateUser
              deleteUser
              createRoom
              updateRoom
              deleteRoom
              createRecording
              updateRecording
              deleteRecording
              createActivity
              updateActivity
              deleteActivity
              createAllowedDomain
              updateAllowedDomain
              deleteAllowedDomain
              createAPIKey
              updateAPIKey
              deleteAPIKey
              createBalance
              updateBalance
              deleteBalance
              createMeetingRoomParams
              updateMeetingRoomParams
              deleteMeetingRoomParams
              createRecordingSettings
              updateRecordingSettings
              deleteRecordingSettings
              createSubUser
              updateSubUser
              deleteSubUser
              createTicket
              updateTicket
              deleteTicket
              createTransaction
              updateTransaction
              deleteTransaction
              createUserSettings
              updateUserSettings
              deleteUserSettings
            }
            rateUsage{
              createUser
              updateUser
              deleteUser
              createRoom
              updateRoom
              deleteRoom
              createRecording
              updateRecording
              deleteRecording
              createActivity
              updateActivity
              deleteActivity
              createAllowedDomain
              updateAllowedDomain
              deleteAllowedDomain
              createAPIKey
              updateAPIKey
              deleteAPIKey
              createBalance
              updateBalance
              deleteBalance
              createMeetingRoomParams
              updateMeetingRoomParams
              deleteMeetingRoomParams
              createRecordingSettings
              updateRecordingSettings
              deleteRecordingSettings
              createSubUser
              updateSubUser
              deleteSubUser
              createTicket
              updateTicket
              deleteTicket
              createTransaction
              updateTransaction
              deleteTransaction
              createUserSettings
              updateUserSettings
              deleteUserSettings
          
            }

            userRooms {
              activeCount
              scheduledCount
              endedCount
              active_demo
              scheduled_demo
              ended_demo
              active_sandbox 
              scheduled_sandbox
              ended_sandbox
              active_production
              scheduled_production
              ended_production

            }
            userTickets {
              open
              closed
              resolved
              pending
            }
            userAction{
              create
              delete
              update
              subscribe
              unsubscribe
              other
            }
            userActivity{
              total
            }
            allowedDomains {
              statusCounts {
                active
                inactive
                pending
              }
              institutionLoginCounts {
                true
                false
              }
            }
            subUsers {
              total
              active
              inactive
              suspended
            }

            meetingRoomParams {
              _id
              itemPageLimit
              mediaType
              addCoHost
              targetOrientation
              targetOrientationHost
              targetResolution
              targetResolutionHost
              allowScreenSharing
              type
              audioSetting
              videoSetting
              screenshareSetting
              chatSetting
              safeRoom
              autoStartSafeRoom
              safeRoomAction
            }
            recordingSettings {
              _id
              recordingAudioPausesLimit
              recordingAudioSupport
              recordingAudioPeopleLimit
              recordingAudioParticipantsTimeLimit
              recordingVideoPausesLimit
              recordingVideoSupport
              recordingVideoPeopleLimit
              recordingVideoParticipantsTimeLimit
              recordingAllParticipantsSupport
              recordingVideoParticipantsSupport 
              recordingAllParticipantsFullRoomSupport
              recordingVideoParticipantsFullRoomSupport
              recordingPreferredOrientation
              recordingSupportForOtherOrientation
              recordingMultiFormatsSupport
              recordingHLSSupport
              recordingAddWatermark
              recordingWatermarkPath
              recordingWatermarkPosition
            }
            refMeetingRoomParams {
              _id
              itemPageLimit
              mediaType
              addCoHost
              targetOrientation
              targetOrientationHost
              targetResolution
              targetResolutionHost
              allowScreenSharing
              type
              audioSetting
              videoSetting
              screenshareSetting
              chatSetting
              safeRoom
              autoStartSafeRoom
              safeRoomAction
            }
            refRecordingSettings {
              _id
              recordingAudioPausesLimit
              recordingAudioSupport
              recordingAudioPeopleLimit
              recordingAudioParticipantsTimeLimit
              recordingVideoPausesLimit
              recordingVideoSupport
              recordingVideoPeopleLimit
              recordingVideoParticipantsTimeLimit
              recordingAllParticipantsSupport
              recordingVideoParticipantsSupport 
              recordingAllParticipantsFullRoomSupport
              recordingVideoParticipantsFullRoomSupport
              recordingPreferredOrientation
              recordingSupportForOtherOrientation
              recordingMultiFormatsSupport
              recordingHLSSupport
              recordingAddWatermark
              recordingWatermarkPath
              recordingWatermarkPosition
            }
            userSettings {
              _id
              theme
              notifications
              preferredNotification
              defaultRecordOptions
              recordParameters
              user{
                _id
                email
                username
              }
              subuser{
                _id
                email
                username
              }
            }
            userTransactions{
              other
              credit
              debit
            }
            userBalance{

              _id

              meetingMinutesActual {
                used
                available
                total
              }
              recordingMinutesActual {
                used
                available
                total
              }
              meetingMinutesBonus {
                used
                available
                total
              }
              recordingMinutesBonus {
                used
                available
                total
              }
              meetingMinutesDemo {
                used
                available
                total
              }
              recordingMinutesDemo {
                used
                available
                total
              }
              meetingMinutesSandbox {
                used
                available
                total
              }
              recordingMinutesSandbox {
              used
                available
                total
              }

            }
            
            userAPIKeys{
              _id
              apiKey
              status
              type
              user{
                username
              }
            }

            verifiedLoginDevices
            verifiedLoginIPs
            createdAt
      
          }
      }
  
    `,
      variables: {
        input: findUserInput,
        force: force,
        adminKey: adminKey
      }
    }

    return requestBody
  },

  userLoginRegisterRequestMulti: async (requestBody, baseUrl) => {


    try {
      const res = await fetch(baseUrl, {
        method: 'POST',
        body: requestBody,
        headers: {
            // Set the Content-Type to 'multipart/form-data'
            'Content-Type': 'multipart/form-data',
        },
    });

      const errorData = await res.json();

     
      try {

        if (errorData.errors && errorData.errors.length > 0) {
          const errorMessage = errorData.errors[0].message;
          return errorMessage //'failed'
        }


      } catch (error) {

      }


      const resData = await errorData;

      return resData



    } catch (err) {

      return "Sorry! something went wrong, try again."

    }
  },



  userLoginRegisterRequest: async (requestBody, baseUrl) => {


    try {
      const res = await fetch(baseUrl, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json'
        }
      })


      const errorData = await res.json();

     
      try {

        if (errorData.errors && errorData.errors.length > 0) {
          const errorMessage = errorData.errors[0].message;

        

          if (modeDeploy == "environment") {
            return errorMessage //'failed'
          } else {
            return 'Sorry! something went wrong, try again.'
          }
          
        }


      } catch (error) {
        
      }


      const resData = await errorData;

      return resData



    } catch (err) {

    

      return "Sorry! something went wrong, try again."

    }
  }

}





