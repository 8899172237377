
module.exports = {


    getForums: async (specificPostInput,force,adminKey) => {
        const requestBody = {
            query: `
              query PostsSpecific($input: SpecificPostInput!, $force: Boolean, $adminKey: String) {
                posts_specific(input: $input, force: $force, adminKey: $adminKey) {
                    _id
                    title
                    content
                    user{
                        _id
                        username
                        email
                        createdAt
                        role
                        numberPosts
                        numberPostReplies
                        numberLikedPosts
                    }
                    subuser{
                        _id
                        username
                        email
                        createdAt
                        role
                        numberPosts
                        numberPostReplies
                        numberLikedPosts
                    }
                    timeStamp
                    upvotes
                    likes
                    category
                    views
                    attachments
                    postNumber
                    createdAt
                    updatedAt
                    replies{
                        _id
                        user{
                            _id
                            username
                            email
                            role
                            createdAt
                        }
                        subuser{
                            _id
                            username
                            email
                            role
                            createdAt
                        }
                        content
                        timeStamp
                        upvotes
                        likes
                        views
                        createdAt
                        updatedAt
                    }
                }
              }
            `,
            variables: {
          
                input: specificPostInput,
                force: force,
                adminKey: adminKey
              },
              
            
          };

        return requestBody;
    },
      

    createForumInput: async (postInput, force, adminKey) => {

      
        const requestBody = {
            query: `
                mutation CreatePost($input: PostInput!,$force: Boolean, $adminKey: String) {
                createPost(input: $input,force: $force, adminKey: $adminKey) {
                    _id
                }
                }
            `,
            variables: {
                input: postInput,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },


    updateForumInput: async (id, postInput, force, adminKey) => {
        const requestBody = {
            query: `
            mutation UpdatePost($id: ID!, $input: PostInput!,$force: Boolean, $adminKey: String) {
              updatePost(id: $id, input: $input,force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                id: id,
                input: postInput,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },

    likeForumInput: async (likeinput, force, adminKey) => {
        const requestBody = {
            query: `
            mutation LikePost($input: LikePostInput!,$force: Boolean, $adminKey: String) {
              likePost(input: $input,force: $force, adminKey: $adminKey) {
                success
              }
            }
          `,
            variables: {
                input: likeinput,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },

    updateForumInputArray: async (ids, inputArray, force, adminKey) => {
        const requestBody = {
            query: `
            mutation UpdatePostsArray($ids: [ID!]!, $inputArray: [PostInput!]!, $force: Boolean, $adminKey: String) {
              updatePostsArray(ids: $ids, inputArray: $inputArray, force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                ids: ids,
                inputArray: inputArray,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },


    deleteForumInput: async (id, force, adminKey) => {
        const requestBody = {
            query: `
            mutation DeletePost($id: ID!, $force: Boolean, $adminKey: String) {
              deletePost(id: $id, force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                id: id,
                force: force,
                adminKey: adminKey,
            },
        };
        return requestBody;
    },

    deleteForumInputArray: async (ids, force, adminKey) => {
        const requestBody = {
            query: `
            mutation DeletePostsArray($ids: [ID!]!, $force: Boolean, $adminKey: String) {
              deletePostsArray(ids: $ids, force: $force, adminKey: $adminKey) {
                _id
              }
            }
          `,
            variables: {
                ids: ids,
                force: force,
                adminKey: adminKey
            },
        };
        return requestBody;
    },


}






