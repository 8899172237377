import { ca } from "date-fns/locale";
import React from "react"

export default React.createContext ({
    token: null,
    userId: null,
    login: (token,userId,tokenExpiration) => {},
    switchAccount: (role) => {},
    logout: () => {},
    forget:null,
    signup:null,
    baseUrl: " ",
    reset: null,
    authswitch :(forget,signup,reset) => {},
    res_email:null,
    res_phone:null,
    reset_token:" ",
    authdetails: (res_email,res_phone,reset_token) => {},
    black: '#000000',
    white: '#FFFFFF',
    decHeight: null,
    isloading:null,
    setload: (isloading) => {},
    reset_pass: null,
    resetswitch : (reset_pass) => {},
    authdatainfo: '',
    attemptauth:false,
    setauthattempts : (authdatainfo,attemptauth) => {},
    mainScreen: null,
    setMainScreen: (mainScreen) => {},
    isVerified: false,
    updateSummaryData: (summaryData) => {},
    summaryData: null,
    tempRole: null,
    tempUsername: null,
    tempEmail: null,
    switchAccount: (usernamed,emailed,option) => {},
    meetingRoomData: null,
    updateMeetingRoomData: (meetingRoomData) => {},
    canSubscribe: false,
    currentSubscription: null,
    currentValidSubscription: null,
    updateSubscription: (canSubscribe, currentSubscription, currentValidSubscription) => {},
});