const tintColorLight = '#000';
const tintColorAlt = '#2f95dc';
const tintColorDark = '#fff';

const Colors = {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    alttint:tintColorAlt,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    greenish:'#56ccbe',
    grayish:'#dde2ed'
    
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    alttint:tintColorAlt,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    greenish:'#56ccbe',
    grayish:'#dde2ed'
  },
};

export default Colors;
